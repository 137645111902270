import { FlowStepRuleFunctionVariadic } from '../flow-engine';
import { APIContextActions } from '../../api/APIContext';
import { getMarvelLogger } from '@marvel-common/bp-digital-logging';
interface partnerStatusRequestInfo {
  accessToken?: string;
  currentPartner: string;
  userId?: string;
  currentPartnerRegion?: string;
  apiContext: APIContextActions;
}
export const rule: FlowStepRuleFunctionVariadic = async function rule(_apiContext, flowOptions) {
  const { currentPartner, currentPartnerRegion, accessToken, user } = flowOptions;
  const userId = user?.userId;
  if (currentPartner === 'amazon-prime' && currentPartnerRegion === 'us') {
    return await getPartnerStatus({
      apiContext: _apiContext,
      accessToken,
      currentPartner,
      userId,
      currentPartnerRegion,
    });
  }
  return false;
};

const getPartnerStatus = async ({
  apiContext,
  accessToken,
  currentPartner,
  userId,
  currentPartnerRegion,
}: partnerStatusRequestInfo): Promise<boolean> => {
  const logger = getMarvelLogger('getPartnerStatus');
  logger.info(`getting getPartnerStatus: ${currentPartner}`);
  const partnerStatusResponse = await apiContext.getPartnerStatus({
    idToken: accessToken,
    partnerType: currentPartner,
    userId,
    region: currentPartnerRegion,
  });
  return !partnerStatusResponse?.partnerStatus?.isActive;
};
