import { useCallback } from 'react';

import { useAPIContext } from '../api/APIContext';
import { IBFFClientResult, IGraphQLErrors } from '../api/graphql/AppSyncClient';
import history from '../routing/BrowserHistory';
import { useApplicationContext } from '../contexts/ApplicationContext';
import { useOAuthContext } from '../auth/OAuthContext';
import { usePersistenceContext } from '../persistence/PersistenceContext';
import { LinkLoyaltyInfoInput } from '../api/graphql/mutations/linkLoyaltyInfo';
import { ILoyaltyInfo } from '../user/ILoyaltyInfo';
import { PartnerInformation } from '../api/graphql/mutations/partnerLinking';
import { LoyaltyInfo } from '../user/IUser';
import { redirectToPartnerPage } from '../helpers/CustomRedirector';

export interface CreateLoyaltyInfo {
  idToken: string;
  accessToken?: string;
  loyaltyId: string;
  userId: string;
  loyaltyType: string;
  partnerType: string;
  partnerLevelId: string;
  partnerRegion: string;
  linkLoyaltyInfo: (accessToken: string, input: LinkLoyaltyInfoInput) => Promise<IBFFClientResult>;
  setPartnerLinking?: (accessToken: string, input: PartnerInformation) => Promise<IBFFClientResult>;
  pollLoyaltyInfo: any;
  fromRegister?: boolean;
}

export interface PartnerLinkingObj {
  partnerType: string;
  partnerRegion: string;
  accessToken?: string;
  userId: string;
  fromRegister: boolean;
  setPartnerLinking?: (accessToken: string, input: PartnerInformation) => Promise<IBFFClientResult>;
}

export async function createLoyaltyWithPolling({
  idToken,
  accessToken,
  loyaltyId,
  loyaltyType,
  partnerType,
  userId,
  partnerLevelId,
  partnerRegion,
  linkLoyaltyInfo,
  setPartnerLinking,
  pollLoyaltyInfo,
  fromRegister = false,
}: CreateLoyaltyInfo): Promise<ILoyaltyInfo | undefined> {
  const partnerLinkingObj: PartnerLinkingObj = {
    partnerType,
    accessToken,
    partnerRegion,
    userId,
    setPartnerLinking,
    fromRegister,
  };

  try {
    const linkLoyaltyInfoInput = {
      loyaltyId,
      loyaltyType,
      partnerType,
      partnerLevelId,
      partnerRegion,
      cipToken: partnerType === 'amazon-prime' ? accessToken : undefined,
    };

    // Save Loyalty ID in persistence so that we're not accidentally forwarded to the
    // welcome page
    const loyaltyInfo = (await linkLoyaltyInfo!(idToken!, linkLoyaltyInfoInput)).data;
    return returnLoyaltyInfo(partnerLinkingObj, loyaltyInfo);
  } catch (err) {
    const graphQLError = err as IGraphQLErrors;

    const isTimeout = !!graphQLError.message && graphQLError.message.indexOf('Endpoint request timed out') !== -1;

    if (isTimeout) {
      // graphQLError.networkError.statusCode === 408
      const loyaltyInfo = await pollLoyaltyInfo(idToken!, partnerType, loyaltyType, partnerRegion);
      if (loyaltyInfo?.loyaltyId) {
        return returnLoyaltyInfo(partnerLinkingObj, loyaltyInfo);
      }
    }

    if (
      graphQLError.graphQLErrors &&
      graphQLError.graphQLErrors.length > 0 &&
      (graphQLError.message ===
        'GraphQL error: "The mobile phone number is already registered on BP Me Rewards(FIS)"' ||
        graphQLError.message === 'GraphQL error: "The SFID is already registered on BP Me Rewards(FIS)"')
    ) {
      history.push('/welcome?alreadyRegistered=true');
    } else {
      history.push(`/500?${graphQLError.message}`);
    }
    return;
  }
}

const returnLoyaltyInfo = async (
  partnerLinkingObj: PartnerLinkingObj,
  loyaltyInfo: LoyaltyInfo,
): Promise<ILoyaltyInfo | undefined> => {
  try {
    // if partner is amazon prime, call the partner linking
    if (partnerLinkingObj.partnerType === 'amazon-prime') {
      partnerLinking({
        partnerRegion: partnerLinkingObj.partnerRegion,
        accessToken: partnerLinkingObj.accessToken,
        setPartnerLinking: partnerLinkingObj.setPartnerLinking,
        partnerType: partnerLinkingObj.partnerType,
        userId: partnerLinkingObj.userId,
        fromRegister: partnerLinkingObj.fromRegister,
      });
    }

    return {
      loyaltyId: loyaltyInfo.loyaltyId!,
      loading: 'completed',
      loyaltyBarcode: loyaltyInfo.loyaltyIdBarCode!,
    };
  } catch (err) {
    const graphQLError = err as IGraphQLErrors;
    history.push(`/500?${graphQLError.message}`);
    return;
  }
};

const partnerLinking = async ({
  accessToken,
  setPartnerLinking,
  partnerType,
  partnerRegion,
  userId,
  fromRegister,
}: PartnerLinkingObj) => {
  setPartnerLinking!(accessToken!, {
    benefitStatus: 'Active',
    linkChannel: 'Online',
    partnerType,
    userId,
    partnerRegion,
  });

  if (!fromRegister) {
    redirectToPartnerPage(partnerType, history);
  }
};

export const useSetLoyaltyInfoWithPolling = () => {
  const { idToken, accessToken, user } = useOAuthContext();
  const { partnerLevelId, setLoyaltyInfo } = usePersistenceContext();
  const { linkLoyaltyInfo, pollLoyaltyInfo, setPartnerLinking } = useAPIContext();
  const { currentLoyaltyType, currentPartner, currentPartnerRegion } = useApplicationContext();

  const callback = useCallback(
    async (loyaltyId: string): Promise<boolean> => {
      const userId: string = user?.userId ?? '';
      const res = await createLoyaltyWithPolling({
        idToken: idToken || '',
        loyaltyId,
        accessToken,
        userId,
        loyaltyType: currentLoyaltyType.toLowerCase(),
        partnerType: currentPartner.toLowerCase(),
        partnerLevelId,
        partnerRegion: currentPartnerRegion.toLowerCase(),
        linkLoyaltyInfo,
        pollLoyaltyInfo,
        setPartnerLinking,
        fromRegister: true,
      });

      setLoyaltyInfo({
        loading: 'completed',
        loyaltyId: res?.loyaltyId || '',
        loyaltyBarcode: res?.loyaltyBarcode || '',
      });

      return !!res;
    },
    [
      currentLoyaltyType,
      currentPartner,
      currentPartnerRegion,
      idToken,
      linkLoyaltyInfo,
      partnerLevelId,
      pollLoyaltyInfo,
      setLoyaltyInfo,
    ],
  );

  return [callback];
};
