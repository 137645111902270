import { FlowStepName, FlowStepRuleFunction, FlowStepRuleFunctionVariadic } from './flow-engine';
import { rule as consentRule } from './rules/consent-rule';
import { rule as lastLocationRule } from './rules/last-location-rule';
import { rule as welcomeRule } from './rules/welcome-rule';
import { rule as partnerStatus } from './rules/partner-status-rule';
import { rule as homeRule } from './rules/home-rule';
import { rule as successRule } from './rules/success-rule';

import { rule as syncUserRule } from './rules/sync-user-rule';
import { rule as endOfRedemptionPeriodRule } from './rules/end-of-redemption-period-rule';
import { rule as registrationRule } from './rules/registration-rule';

import { rule as createLoyaltyRule } from './rules/create-loyalty-rule';

export default function getRule(step: FlowStepName): FlowStepRuleFunction | FlowStepRuleFunctionVariadic {
  const rules: Record<FlowStepName, FlowStepRuleFunction | FlowStepRuleFunctionVariadic> = {
    consent: consentRule,
    'partner-status': partnerStatus,
    'last-location': lastLocationRule,
    welcome: welcomeRule,
    home: homeRule,
    success: successRule,
    'sync-user-then-to-welcome-or-home': syncUserRule,
    'end-of-redemption-period': endOfRedemptionPeriodRule,
    registration: registrationRule,
    'create-loyalty': createLoyaltyRule,
  };
  return rules[step];
}
