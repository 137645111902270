import _ from 'lodash';
import { getMarvelLogger } from '@marvel-common/bp-digital-logging';
import { AuthProvider } from '@marvel-common/ridehailing-idp';

import { IUser } from '../user/IUser';
import { Region } from '../interfaces/Region';
import { RegionType } from '../helpers/RegionType';
import { ISettingsResult } from '../api/graphql/queries/getAppSettings';
import { getErrorMessage } from '../helpers/errorMessage';

import { completion } from './actions/sync-user-action';

import uberProFuelFlowAu from '../configs/uber-pro/fuel/flow/au.json';
import uberProFuelFlowMx from '../configs/uber-pro/fuel/flow/mx.json';
import uberProFuelFlowNl from '../configs/uber-pro/fuel/flow/nl.json';
import uberProFuelFlowUk from '../configs/uber-pro/fuel/flow/uk.json';
import uberProFuelFlowUs from '../configs/uber-pro/fuel/flow/us.json';
import amazonPrimeFuleFlowUs from '../configs/amazon-prime/fuel/flow/us.json';

import { ProgramType } from '../interfaces/ProgramType';
import { PartnerType } from '../interfaces/Partner';
import { APIContextActions } from '../api/APIContext';
import getRule from './flow-rule-factory';
import getAction from './flow-action-factory';

export const flowSteps = [
  'registration',
  'consent',
  'partner-status',
  'last-location',
  'welcome',
  'home',
  'success',
  'sync-user-then-to-welcome-or-home',
  'end-of-redemption-period',
  'create-loyalty',
] as const;

export type FlowStepName = (typeof flowSteps)[number];

export interface Flow {
  steps: FlowStep[];
  components: FlowComponent[];
}

export interface FlowComponent {
  name: FlowStepName;
  path: string;
  component: string;
}

export interface FlowStep {
  order: number;
  name: FlowStepName;
  nextStep?: FlowStep;
}

export interface FlowOptions {
  user: IUser;
  region: Region;
  idToken: string;
  accessToken: string;
  currentPartner: PartnerType;
  currentProgram: ProgramType;
  currentPartnerRegion: RegionType;
  currentLoyaltyType: string;
  currentAppSettings: ISettingsResult;
  setCurrentAppSettings: (settings: ISettingsResult) => void;
  suggestedAuthProvider: AuthProvider;
}

export type FlowStepRuleFunction = (apiActions: APIContextActions) => Promise<boolean>;
export type FlowStepRuleFunctionVariadic = (
  apiActions: APIContextActions,
  flowOptions: Partial<FlowOptions>,
) => Promise<boolean>;
export type FlowStepActionFunction = (apiActions: APIContextActions) => void;
export type FlowStepActionFunctionVariadic = (apiActions: APIContextActions, flowOptions: Partial<FlowOptions>) => any;

export interface FlowStepRuleAction {
  stepName: FlowStepName;
  rule: FlowStepRuleFunction | FlowStepRuleFunctionVariadic;
  action: FlowStepActionFunction | FlowStepActionFunctionVariadic;
  feedback?: (data: unknown) => any;
  completion?: (flowStepRuleAction: FlowStepRuleAction, result: any) => any;
}

type FlowConfig = Record<PartnerType, Record<ProgramType, Record<string, Flow>>>;

const flowConfig: FlowConfig = {
  'uber-pro': {
    fuel: {
      au: uberProFuelFlowAu as Flow,
      mx: uberProFuelFlowMx as Flow,
      nl: uberProFuelFlowNl as Flow,
      uk: uberProFuelFlowUk as Flow,
      us: uberProFuelFlowUs as Flow,
    },
    pulse: {
      //
    },
  },
  'amazon-prime': {
    fuel: {
      us: amazonPrimeFuleFlowUs as Flow,
    },
    pulse: {
      //
    },
  },
};

const flowStepActions: FlowStepRuleAction[] = flowSteps.map((stepName: FlowStepName) => ({
  stepName,
  rule: getRule(stepName),
  action: getAction(stepName),
}));

function mapFeedbackCompletion(feedback: (data: any) => string): void {
  flowStepActions.map((flowStepRuleAction) => {
    if (flowStepRuleAction.stepName === 'sync-user-then-to-welcome-or-home') {
      flowStepRuleAction.feedback = feedback;
      flowStepRuleAction.completion = completion;
    }
    return flowStepRuleAction;
  });
}

export function isFlowStepRuleAction(result: FlowStepRuleAction): result is FlowStepRuleAction {
  return result && result.stepName !== undefined;
}

export async function runFlowEngine(
  apiActions: APIContextActions,
  flowOptions: Partial<FlowOptions>,
  setUserData: (data: any) => string,
): Promise<any> {
  try {
    mapFeedbackCompletion(setUserData);

    const flow = getFlow(flowOptions.currentPartner!, flowOptions.currentProgram!, flowOptions.currentPartnerRegion!);
    const flowStepRuleAction: FlowStepRuleAction | null | undefined = await nextStepAction(
      apiActions,
      flow!.steps,
      flowOptions,
    );

    const result = await executeStepAction(apiActions, flowStepRuleAction!, flowOptions);
    flowCallback({ flowStepRuleAction, result, flowOptions });
    return { flowStepRuleAction, result };
  } catch (error) {
    throw new Error(`Flow engine failure: ${error}`);
  }
}

export function getFlow(partner: PartnerType, program: ProgramType, region: string): Flow {
  try {
    const flow = flowConfig[partner][program][region];

    if (!flow) {
      throw new Error('Flow engine failure: Unknown flow');
    }

    if (!flow.steps?.length) {
      throw new Error(`Flow engine requires a minimum of 1 step`);
    }

    flow.steps = _.orderBy(flow.steps, (step) => step.order);
    flow.steps.map((step, i) => (step.nextStep = flow.steps[i + 1]));

    return flow;
  } catch (error: unknown) {
    const errorWithMessage = getErrorMessage(error);
    throw new Error(`Unable to init flow engine: ${errorWithMessage.message}`);
  }
}

async function getFlowStepRuleAction(
  apiActions: APIContextActions,
  flowStep: FlowStep,
  flowOptions: Partial<FlowOptions>,
): Promise<{ allowed: boolean; flowStepRuleAction: FlowStepRuleAction | undefined }> {
  const flowStepRuleAction = flowStepActions.find((action) => action.stepName === flowStep.name);

  const rule = flowStepRuleAction?.rule as FlowStepRuleFunction | FlowStepRuleFunctionVariadic;
  const allowed = await rule(apiActions, flowOptions);

  return { allowed, flowStepRuleAction };
}

async function executeStepAction(
  apiActions: APIContextActions,
  flowStepRuleAction: FlowStepRuleAction,
  flowOptions: Partial<FlowOptions>,
): Promise<any> {
  if (!flowStepRuleAction) {
    return;
  }

  const action = flowStepRuleAction.action as FlowStepActionFunctionVariadic | FlowStepActionFunction;
  return action(apiActions, flowOptions);
}

async function nextStepAction(
  apiActions: APIContextActions,
  partnerFlowSteps: FlowStep[],
  flowOptions: Partial<FlowOptions>,
): Promise<FlowStepRuleAction | null> {
  const logger = getMarvelLogger('nextStepAction');
  for (const flowStep of partnerFlowSteps) {
    if (!flowSteps.includes(flowStep.name)) {
      throw new Error('No action configured for step: ' + flowStep.name);
    }

    const { allowed, flowStepRuleAction } = await getFlowStepRuleAction(apiActions, flowStep, flowOptions);

    logger.info('flowStepRuleAction', { flowStepRuleAction, allowed });
    if (allowed) {
      return flowStepRuleAction!;
    }
  }

  return null;
}

function parseFlowCallbackResponse(response: any): {
  flowStepRuleAction: FlowStepRuleAction | undefined;
  result: any | undefined;
} | null {
  const { flowStepRuleAction, result } = response;
  if (isFlowStepRuleAction(flowStepRuleAction)) {
    return { flowStepRuleAction, result };
  }
  return null;
}

function flowCallback(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  response: any,
): void {
  const parsedResponse = parseFlowCallbackResponse(response);
  if (parsedResponse?.flowStepRuleAction && parsedResponse?.flowStepRuleAction.completion) {
    parsedResponse?.flowStepRuleAction.completion(parsedResponse.flowStepRuleAction, parsedResponse.result);
  }
}
