import { Persistence } from '../../persistence/Persistence';
import { getHash } from '../../helpers/CryptoHelper';

export enum OAuth2GrantCallbackMode {
  Unsupported = 0,
  ImplicitFlow = 1 << 0, // 0001 = 1
  ImplicitFlowHybrid = 1 << 1, // 0010 = 2
  AuthenticationCode = 1 << 2, // 0100 = 4
  AuthenticationCodeHybrid = 1 << 3, // 1000 = 8
  CipFlow = 1 << 4, // 10000 = 16
  ImplicitFlowAuthenticationCodeHybrid = ImplicitFlowHybrid | AuthenticationCodeHybrid, // 1010 = 10
}

function isCipAuthCallback(url: Location): boolean {
  if (url === undefined || url.search === undefined || url.search === '') return false;
  return url.search.includes('code=') && url.search.includes('state=');
}

/**
 * Checks the url for callback mode
 * @returns true if has code is param
 */
export function getOAuth2CallbackMode(location: Location): OAuth2GrantCallbackMode {
  if (isCipAuthCallback(location)) {
    return OAuth2GrantCallbackMode.CipFlow;
  }
  return OAuth2GrantCallbackMode.Unsupported;
}

export type AuthenticationCodeCallbackResult = {
  code?: string;
  error?: string;
};

/**
 *
 * Hashes user id and saves
 * @returns true if stored
 */
export function saveUserHashedId(id: string): boolean {
  try {
    const hashed = getHash(id);

    Persistence.setHashedObject(hashed);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 *
 * Checks if user id is same as stored user id (local stoarge)
 * @returns true if same
 */
export function isSameUser(id: string): boolean {
  try {
    const hashed = Persistence.getHashedObject();

    const currentUserIdHash = getHash(id);

    if (hashed) {
      return currentUserIdHash === hashed;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export function getCodeAndState(location: Location): any {
  // Check URL for query parameters
  const url = new URL(location.toString());
  const params = url.searchParams;
  const code = params.get('code');
  const state = params.get('state');

  if (!code || !state) {
    throw new Error('No code and state found in the URL.');
  }
  return { code, state };
}
