/* eslint-disable */
import { useState, useEffect, useContext, useReducer, ReactElement, createContext } from 'react';

import { TokenManager, Config, UserManager } from '@forgerock/javascript-sdk';

import history from '../routing/BrowserHistory';
import { CipUserDto, IUser } from '../user/IUser';
import UserReducer, { UserMutationAction } from './UserReducer';
import {
  saveUserHashedId,
  isSameUser,
  getOAuth2CallbackMode,
  OAuth2GrantCallbackMode,
  getCodeAndState,
} from './utils/auth';
import { Persistence } from '../persistence/Persistence';
import { useAPIContext } from '../api/APIContext';
import { useApplicationContext } from '../contexts/ApplicationContext';
import { getPartnerRegionConfig } from '../helpers/PartnerRegionPropertySelector';
import { CIPJWTPayload, decodeToken } from '../helpers/JWT';
import { useWindowLocation } from '../hooks/useWindowLocation';
import { mapCipUser } from '../utils/user';

function saveUser(u: IUser): IUser {
  if (u!.userId) Persistence.setStorageEncryptionKey(u!.userId);
  if (isSameUser(u!.userId as string) === false) {
    Persistence.resetUserData();
  }

  saveUserHashedId(u!.userId as string);

  return u;
}

export type OAuthContextResult = {
  success: boolean;
  failure: boolean;
  error?: string;
};

export interface AuthState {
  readonly isAuthenticated: boolean;
  readonly isAuthenticating: boolean;
  readonly idToken?: string;
  readonly accessToken?: string;
  readonly user?: IUser;
  readonly audience?: string;
  dispatch?: ((action: UserMutationAction) => void) | undefined;
}

export interface OAuthContextProviderOptions {
  children?: ReactElement;
  redirectToPath: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isAuthenticating: true,
  idToken: undefined,
  accessToken: undefined,
  user: undefined,
  audience: undefined,
};

export const OAuthContext = createContext<AuthState>(initialState);

export const useOAuthContext = (): AuthState => useContext(OAuthContext);

export const OAuthContextProvider = ({ children, redirectToPath }: OAuthContextProviderOptions): JSX.Element => {
  const location = useWindowLocation();
  const [, dispatch] = useReducer(UserReducer, initialState);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [idToken, setIdToken] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [audience, setAudience] = useState('');
  const [user, setUser] = useState<IUser>();

  const { currentPartner, currentPartnerRegion, currentLoyaltyType } = useApplicationContext();
  const { saveUserTokensV2, getCipUser } = useAPIContext();

  useEffect(() => {
    (async () => {
      if (!currentPartner || !currentPartnerRegion) {
        return;
      }
      const partnerRegionConfig = getPartnerRegionConfig(currentPartner, currentPartnerRegion);

      setIsAuthenticated(false);
      setIsAuthenticating(false);

      const callbackMode = getOAuth2CallbackMode(location);

      if (callbackMode === OAuth2GrantCallbackMode.CipFlow) {
        try {
          // Even if requested provider is CIP don't use it without a config.
          if (!partnerRegionConfig.cipConfig) {
            throw new Error('Cannot use this provider');
          }

          Config.set(partnerRegionConfig.cipConfig);

          const { code, state } = getCodeAndState(location);

          // Get currently logged in user
          const tokens = await TokenManager.getTokens({
            query: { code: code, state: state },
          });

          if (!tokens || !tokens.idToken || !tokens.accessToken || !tokens.refreshToken) {
            throw new Error('CIP tokens not found.');
          }

          setAccessToken(tokens.accessToken);

          setIdToken(tokens.idToken);

          const decodedToken = decodeToken<CIPJWTPayload>(tokens.accessToken);

          if (!decodedToken) {
            throw new Error('Cannot decode CIP access token.');
          }

          setAudience(decodedToken!.aud);
          // tokens only saved for uberpro, since it is having a microsite integrated with uberdriver app

          await saveUserTokensV2(
            tokens.idToken,
            tokens.refreshToken,
            decodedToken.socialAccessToken,
            decodedToken.socialRefreshToken,
          );

          UserManager.getCurrentUser()
            .then(async (rslt) => {
              const cipUser = rslt as CipUserDto;
              const userData = await getCipUser(
                tokens.idToken!,
                currentPartner,
                currentLoyaltyType,
                currentPartnerRegion,
                decodedToken?.external_id!,
              );

              const mappedUser = mapCipUser(userData.userPii, userData.user, cipUser);

              setUser(saveUser(mappedUser));

              setIsAuthenticated(true);
              history.replace(redirectToPath);
            })
            .catch(() => {
              history.replace('/500?result=' + encodeURIComponent('Failed to login'));
            });
        } catch (error) {
          history.replace('/500?result=' + encodeURIComponent('Failed to login'));
        }
      }
    })();
  }, [redirectToPath, location]);

  return (
    <OAuthContext.Provider
      value={{
        isAuthenticated,
        isAuthenticating,
        idToken,
        accessToken,
        user,
        audience,
        dispatch,
      }}
    >
      {children}
    </OAuthContext.Provider>
  );
};
