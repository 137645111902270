import deepFreeze from 'deep-freeze-strict';
import { get } from 'lodash';

import { Partner } from './interfaces/Partner';
import { getPartnerData } from './helpers/EnvConfigHelper';

const BUILD_NUMBER = process.env.REACT_APP_BUILD_NUMBER as string;
const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL as string;
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;

const GA4_MEASUREMENT_ID = process.env.REACT_APP_GA4_MEASUREMENT_ID as string;

const BP_APPSYNC_ENDPOINT = process.env.REACT_APP_BP_APPSYNC_ENDPOINT as string;
const BP_APPSYNC_API_KEY = process.env.REACT_APP_BP_APPSYNC_API_KEY as string;
const BP_APPSYNC_REGION = process.env.REACT_APP_BP_APPSYNC_REGION as string;

const BP_APPSYNC_V2_ENDPOINT = process.env.REACT_APP_BP_APPSYNC_V2_ENDPOINT as string;
const BP_APPSYNC_V2_API_KEY = process.env.REACT_APP_BP_APPSYNC_V2_API_KEY as string;
const BP_APPSYNC_V2_REGION = process.env.REACT_APP_BP_APPSYNC_V2_REGION as string;

const FIREBASE_APIKEY = process.env.REACT_APP_FIREBASE_APIKEY as string;
const FIREBASE_AUTHDOMAIN = process.env.REACT_APP_FIREBASE_AUTHDOMAIN as string;
const FIREBASE_DB = process.env.REACT_APP_FIREBASE_DB as string;
const FIREBASE_PID = process.env.REACT_APP_FIREBASE_PID as string;
const FIREBASE_SB = process.env.REACT_APP_FIREBASE_SB as string;
const FIREBASE_SID = process.env.REACT_APP_FIREBASE_SID as string;
const FIREBASE_APPID = process.env.REACT_APP_FIREBASE_APPID as string;

const ROLLBAR_CLIENT_TOKEN = process.env.REACT_APP_ROLLBAR_CLIENT_TOKEN as string;

const AMAZON_LANDING_PAGE = process.env.REACT_APP_AMAZON_LANDING_PAGE as string;
const AMAZON_SUCCESS_PAGE = process.env.REACT_APP_AMAZON_SUCCESS_PAGE as string;
const partners: Partner[] = getPartnerData(ENVIRONMENT);

export const isConfiguredCorrectly = (): [boolean, string[]] => {
  const INCLUDE_ENV_VARS = /(^REACT_APP_)/i;
  const requiredEnvVars = [
    // ********************************************************
    // note: this is injected by the ADO build pipeline, with
    // BUILD_BUILDNUMBER do not put this in the environment
    // variable groups in ADO
    'REACT_APP_BUILD_NUMBER',
    // ********************************************************
    // these are the key environment variables to store in
    // the environment variable groups in ADO
    'REACT_APP_MARVEL_FEATURE_FLAGS_DEFAULT',
    'REACT_APP_ENVIRONMENT',
    'REACT_APP_BP_APPSYNC_ENDPOINT',
    'REACT_APP_BP_APPSYNC_API_KEY',
    'REACT_APP_BP_APPSYNC_REGION',
    'REACT_APP_GA4_MEASUREMENT_ID',
    'REACT_APP_FIREBASE_APIKEY',
    'REACT_APP_FIREBASE_AUTHDOMAIN',
    'REACT_APP_FIREBASE_DB',
    'REACT_APP_FIREBASE_PID',
    'REACT_APP_FIREBASE_SB',
    'REACT_APP_FIREBASE_SID',
    'REACT_APP_FIREBASE_APPID',
    'REACT_APP_ROLLBAR_CLIENT_TOKEN',
    'REACT_APP_PUBLIC_URL',
    'REACT_APP_BP_APPSYNC_V2_ENDPOINT',
    'REACT_APP_BP_APPSYNC_V2_API_KEY',
    'REACT_APP_BP_APPSYNC_V2_REGION',
    'REACT_APP_FIREBASE_URL',
    'REACT_APP_AMAZON_LANDING_PAGE',
    'REACT_APP_AMAZON_SUCCESS_PAGE',
    // ********************************************************
  ];
  function validate(): [boolean, string[]] {
    const raw: Array<string> = Object.keys(process.env).filter((key) => INCLUDE_ENV_VARS.test(key));
    const missingEnvVars = requiredEnvVars.filter((envVar) => !raw.includes(envVar));
    if (missingEnvVars.length > 0) {
      return [false, missingEnvVars];
    }
    return [true, []];
  }
  return validate();
};

export const staticConfig = deepFreeze({
  system: {
    appName: 'microsite',
    buildNumber: BUILD_NUMBER,
    publicUrl: PUBLIC_URL,
    environment: ENVIRONMENT,
  },
  partners,
  appsync: {
    endpoint: BP_APPSYNC_ENDPOINT,
    apiKey: BP_APPSYNC_API_KEY,
    region: BP_APPSYNC_REGION,
  },
  appsyncV2: {
    endpoint: BP_APPSYNC_V2_ENDPOINT,
    apiKey: BP_APPSYNC_V2_API_KEY,
    region: BP_APPSYNC_V2_REGION,
  },
  analytics: {
    ga4MeasurementId: GA4_MEASUREMENT_ID,
  },
  firebase: {
    apiKey: FIREBASE_APIKEY,
    authDomain: FIREBASE_AUTHDOMAIN,
    databaseURL: FIREBASE_DB,
    projectId: FIREBASE_PID,
    storageBucket: FIREBASE_SB,
    messagingSenderId: FIREBASE_SID,
    appId: FIREBASE_APPID,
  },
  rollbar: {
    clientToken: ROLLBAR_CLIENT_TOKEN,
  },
  amazonPrimeEndpoints: {
    landingPage: AMAZON_LANDING_PAGE,
    successPage: AMAZON_SUCCESS_PAGE,
  },
});

/* istanbul ignore next: No need to codecov*/
export default (path: string, fallback = undefined): any => get(staticConfig, path, fallback);
