import linkLoyaltyInfoMutation from 'graphql-tag';

export default linkLoyaltyInfoMutation`
mutation linkLoyaltyInfo($linkLoyaltyInfoInput: LinkLoyaltyInfoInput!) {
    linkLoyaltyInfo(linkLoyaltyInfoInput: $linkLoyaltyInfoInput) {
      loyaltyId
      loyaltyType
    }
  }
`;

export type LinkLoyaltyInfoInput = {
  partnerType: string;
  partnerLevelId: string;
  loyaltyType: string;
  loyaltyId: string;
  password?: string;
  partnerRegion: string;
  cipToken?: string;
};
